.App {
  position: relative;
  text-align: center;
  min-height: 100vh; }

p {
  line-height: 1.5em; }

.App-logo {
  position: absolute;
  animation: App-logo-spin infinite 20s linear;
  height: 100%; }

.App-header {
  position: relative;
  font-size: calc(10px + 2vmin);
  background: #010;
  color: white;
  z-index: 0; }

.app-header-image {
  opacity: 0.7;
  background: url("../images/foundation-crossfit-for-kids.jpg");
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1; }

.App-link {
  color: #61dafb; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.background-image {
  position: absolute;
  background: #f9f9f9;
  opacity: 1 !important;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1; }

@media (max-width: 960px) {
  .header-text {
    margin: 1.25em auto 1.25em;
    font-size: 18px; } }

h1 {
  color: #333333; }
  @media (max-width: 960px) {
    h1 {
      font-size: 18px; } }

.animated {
  color: #444444; }

.disabled {
  opacity: 0.2;
  transition: 0.4s; }

.input-skeptic {
  margin-top: 16px !important;
  margin-bottom: 8px !important;
  width: 100%; }
  .input-skeptic input {
    height: 2em; }

body {
  margin: 0;
  padding: 0;
  background: #212121;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

.animated {
  animation-duration: 0.6s; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px); }
  to {
    opacity: 1;
    transform: translateY(0px); } }

.fadeIn {
  animation-name: fadeIn; }
