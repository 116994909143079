.App {
  position: relative;
  text-align: center;
  min-height: 100vh;
}

p {
  line-height: 1.5em;
}

.App-logo {
  position: absolute;
  animation: App-logo-spin infinite 20s linear;
  height: 100%;
}

.App-header {
  position: relative;
  font-size: calc(10px + 2vmin);
  background: #010;
  color: white;
  z-index: 0;
  // min-height: 40vh;
}

.app-header-image {
  opacity: 0.7;
  background: url('../images/foundation-crossfit-for-kids.jpg');
  background-color: black;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.background-image {
  position: absolute;
  // background: url('../images/BTWB-Background.jpg');
  background: #f9f9f9;
  opacity: 1 !important;
  background-position: center center;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.header-text {
  @media (max-width: 960px) {
    margin: 1.25em auto 1.25em;
    font-size: 18px;
  }
}

h1 {
  color: #333333;
  @media (max-width: 960px) {
    font-size: 18px;
  }
}

.animated {
  color: #444444;
}